import React, { useEffect, useState } from 'react'
import ContactLayout from '../components/ui/layout/ContactLayout'
import BlueBgListView from '../components/ui/sections/blueBgSection/list-view-section'
import BlueBgCardView from '../components/ui/sections/blueBgSection/card-view-section'
import Button from '../components/buttons/Button'
import { IoIosArrowForward } from 'react-icons/io'
import { Link } from 'react-router-dom'
import { getCondos, getHome } from '../api/GET'
import axios from 'axios'
import { API_BASE_URL } from '../config'
import { Helmet } from 'react-helmet'
import FooterDirectory from './FooterDirectory'

const HomesPage = () => {
    const [properties, setProperties] = useState([])
    const [loading, setloading] = useState(false)
    const fetchProperties = async () => {
        setloading(true)
        const data = await getHome();
        if (Array.isArray(data.data?.data)) {
            setProperties(data.data?.data)
        }
        setloading(false)

    }
    useEffect(() => {

        fetchProperties()
    }, [])

    const toggleWishlist = async (loading, setLoading, isSaved, setIsSaved, ListingID) => {
        if (loading) return;
    
        setLoading(true);
    
        const token = JSON.parse(localStorage.getItem('user_data'))?.token; // Retrieve the token from localStorage
        const headers = {
            Authorization: `Bearer ${token}`, // Include the token in the Authorization header
        };
    
        try {
            if (!isSaved) {
                setIsSaved(true); // Mark as saved
                // API call to add to saved
                await axios.post(
                    `${API_BASE_URL}domainparmeter/saved-property/store`,
                    { listing_id: ListingID },
                    { headers } // Pass headers with the request
                );
            } else {
                setIsSaved(false); // Mark as unsaved
                // API call to remove from saved
                await axios.delete(
                    `${API_BASE_URL}domainparmeter/saved-property/${ListingID}`,
                    { headers } // Pass headers with the request
                );
            }
        } catch (error) {
            console.error('Error toggling wishlist:', error);
        } finally {
            setLoading(false);
        }
    };
    

    return (
        <ContactLayout>
             <Helmet>
                    <title>Home Properties in Miami - Luxlife Miami Real Estate LLC</title>
                </Helmet>
            <BlueBgListView title={'Miami Homes'} />
            <div className='mb-24'>
                <BlueBgCardView title={'Homes'} propertyData={properties} loading={loading} toggleWishlist={toggleWishlist} >
                    <div className='flex justify-center mt-14'>
                        <Link to="/new-100/homes-100">
                            <Button title={<div className='flex items-center gap-4'><span>View more</span> <span><IoIosArrowForward className='text-[20px]' /></span></div>} className={'bg-white px-8 text-theme_yellow rounded-md py-3 creato-f-m  tracking-wide btn2'} />
                        </Link>
                    </div>
                </BlueBgCardView>
            </div>

<FooterDirectory />
        </ContactLayout>
    )
}

export default HomesPage