import React from 'react'
import { BsArrowUpRight } from "react-icons/bs";
import sec_3_1 from '../../../../assets/images/sec_3_1.png'
import sec_3_2 from '../../../../assets/images/sec_3_2.png'
import sec_3_3 from '../../../../assets/images/sec_3_3.png'
import sec_3_4 from '../../../../assets/images/sec_3_4.png'
import { Link } from 'react-router-dom';

const Section3 = () => {
    const queryObj = {
        luxuryCondos: 'PropertySubType.in=Condominium&PropertySubType.in=Apartment&PropertySubType.in=Townhouse&ListPrice.gte=1000000',
        family: 'PropertySubType.in=Single Family Residence&PropertySubType.in=Townhouse&PropertySubType.in=Residential&PropertySubType.in=Villa&PropertySubType.in=Multi Family&PropertySubType.in=Income/MultiFamily&PropertySubType.in=Duplex&PropertySubType.in=TownHouse&ListPrice.gte=1000000',
        investment: 'PropertySubType.in=Commercial&ListPrice.gte=1000000',
        villas: 'PropertySubType.in=Condominium&ArchitecturalStyle=Penthouse&ListPrice.gte=1000000'
    }
    return (
        <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 '>
            <Link to={'/miami/luxury-appartment-sale'} state={{ cardFilter: queryObj.luxuryCondos }}>
                <div className='relative dark-bottom-overlay overflow-hidden group cursor-pointer'>
                    <img alt='' src={sec_3_1} className='group-hover:scale-[1.1] transition duration-300 w-[100%] black_white_effect' />
                    <div className='absolute z-30 bottom-8 flex justify-between w-full px-4 items-center text-white'>
                        <p className='text-[22px] leading-[33px] poppins-semibold'>Luxury Condos</p>
                        <div className='p-2 rounded-full bg-theme_yellow transform transition-transform duration-700  w-[62px] h-[62px] flex items-center justify-center  sec-3-arrow'>
                            <BsArrowUpRight className='text-white text-[33px] transform transition-transform duration-700  ' />
                        </div>
                    </div>
                </div>
            </Link>

            <Link to={'/miami/family-homes-sale'} state={{ cardFilter: queryObj.family }}>

                <div className='relative dark-bottom-overlay overflow-hidden group cursor-pointer'>
                    <img alt='' src={sec_3_2} className='group-hover:scale-[1.1] transition duration-300 w-[100%] black_white_effect' />
                    <div className='absolute z-30 bottom-8 flex justify-between w-full px-4 items-center text-white'>
                        <p className='text-[22px] leading-[33px] poppins-semibold'>Family Homes</p>
                        <div className='p-2 rounded-full bg-theme_yellow transform transition-transform duration-700  w-[62px] h-[62px] flex items-center justify-center  sec-3-arrow'>
                            <BsArrowUpRight className='text-white text-[33px] transform transition-transform duration-700  ' />
                        </div>
                    </div>
                </div>
            </Link>

            <Link to={'/miami/luxury-penthouses'} state={{ cardFilter: queryObj.villas }}>

                <div className='relative dark-bottom-overlay overflow-hidden group cursor-pointer'>
                    <img alt='' src={sec_3_3} className='group-hover:scale-[1.1] transition duration-300 w-[100%] black_white_effect' />
                    <div className='absolute z-30 bottom-8 flex justify-between w-full px-4 items-center text-white'>
                        <p className='text-[22px] leading-[33px] poppins-semibold'>Luxury Penthouses</p>
                        <div className='p-2 rounded-full bg-theme_yellow transform transition-transform duration-700  w-[62px] h-[62px] flex items-center justify-center  sec-3-arrow'>
                            <BsArrowUpRight className='text-white text-[33px] transform transition-transform duration-700  ' />
                        </div>
                    </div>
                </div>
            </Link>
            <Link to={'/miami/investment-miami'} state={{ cardFilter: queryObj.investment }}>


                <div className='relative dark-bottom-overlay overflow-hidden group cursor-pointer'>
                    <img alt='' src={sec_3_4} className='group-hover:scale-[1.1] transition duration-300 w-[100%] black_white_effect' />
                    <div className='absolute z-30 bottom-8 flex justify-between w-full px-4 items-center text-white'>
                        <p className='text-[22px] leading-[33px] poppins-semibold'>Investment Properties</p>
                        <div className='p-2 rounded-full bg-theme_yellow transform transition-transform duration-700  w-[62px] h-[62px] flex items-center justify-center  sec-3-arrow'>
                            <BsArrowUpRight className='text-white text-[33px] transform transition-transform duration-700  ' />
                        </div>
                    </div>
                </div>
            </Link>

        </div>

    )
}

export default Section3