import React, { useEffect, useState, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { GoChevronDown } from 'react-icons/go';
import { cn } from '../../../lib/utils';

const SingleSelect = ({ options, placeholder, clsMain, onChange, selectStyle, value }) => {
    const [selectedOption, setSelectedOption] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleOptionClick = (option) => {
        setSelectedOption(option);
        setIsOpen(false);
        if (onChange && typeof onChange === 'function') {
            onChange(option?.value);
        }
    };

    // Handle clicks outside the dropdown
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        // Add event listener when dropdown is open
        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        }

        // Cleanup the event listener
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen]);

    // Set default selected option based on value prop
    useEffect(() => {
        if (value !== '') {
            const defaultOption = options.find(option => option.value === value);
            if (defaultOption) {
                setSelectedOption(defaultOption);
            }
        }
    }, [value, options]);

    return (
        <div
            ref={dropdownRef}
            className={`relative ${clsMain}`}
        >
            <div
                className={cn(
                    "flex justify-between items-center bg-white border border-gray-400 rounded-[20px] p-2 py-4 cursor-pointer text-theme_yellow",
                    selectStyle
                )}
                onClick={toggleDropdown}
            >
                <span className="text-nowrap overflow-hidden">
                    {selectedOption ? selectedOption.label : <span className="text-gray-500 tracking-widest overflow-hidden">{placeholder}</span>}
                </span>
                <GoChevronDown
                    className={`transform transition-transform text-2xl duration-300 ${isOpen ? 'rotate-180' : 'rotate-0'
                        }`}
                />
            </div>

            <AnimatePresence>
                {isOpen && (
                    <motion.ul
                        className="absolute z-[1000000000] mt-1 w-full max-h-[400px] overflow-y-auto bg-white border border-gray-300 rounded-lg shadow-lg"
                        initial={{ opacity: 0, y: -10 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -10 }}
                    >
                        <li
                            className="p-2 cursor-pointer hover:bg-theme_yellow hover:text-white font-semibold tracking-wider"
                            onClick={() => handleOptionClick(null)}
                        >
                            -- empty --
                        </li>
                        {options && options.map((option) => (
                            <li
                                key={option.value}
                                className="p-2 cursor-pointer hover:bg-theme_yellow font-semibold hover:text-white  tracking-wider"
                                onClick={() => handleOptionClick(option)}
                            >
                                {option.label}
                            </li>
                        ))}
                    </motion.ul>
                )}
            </AnimatePresence>
        </div>
    );
};

export default SingleSelect;