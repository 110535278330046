import React from 'react';
import Footer from '../components/ui/sections/FooterSection/Footer';
import Header from '../components/ui/header/Header';
import sec5logo from '../assets/images/singlePreConstruction.svg';
import { Helmet } from 'react-helmet';
import FooterDirectory from './FooterDirectory';

const PrivacyPolicy = () => {
    return (
        <>
            <Helmet>
                <title>Privacy Policy - Luxlife Miami Real Estate LLC</title>
            </Helmet>
            {/* Hero Section */}
            <div className="relative w-full min-h-[500px] md:h-[50vh] overflow-hidden">
                <div className="fixed top-0 z-[10000] w-full">
                    <Header />
                </div>
                <img
                    src={sec5logo}
                    alt="Hero Image"
                    className="w-full absolute h-full object-cover"
                />
                <div className="absolute inset-0 gradient-background"></div>
                <div className="absolute bottom-[calc(50%-80px)] text-center md:left-6 text-white px-4 md:px-8 w-full">
                    <div>
                        <h1 className="creato-f-m text-[48px] lg:text-[4.6vw] mb-6">
                            Privacy Policy
                        </h1>
                    </div>
                </div>
            </div>

            {/* Privacy Policy Content */}
            <div className="container mx-auto px-4 py-8 lg:py-12">
                <div className="rounded-lg p-6 lg:p-10 text-gray-800 mx-[5%]">
                    {/* <h2 className="creato-f-m text-2xl lg:text-3xl font-bold mb-6">
                        Privacy Policy
                    </h2> */}
                    <p className="creato-f-r mb-4">
                        <strong className="creato-f-m">What Information Do We Collect?</strong>
                    </p>
                    <p className="creato-f-l mb-6">
                        We collect information from you when you register on our site, subscribe to our newsletter, respond to a survey, fill out a form to download a market report, or chat with us.
                    </p>
                    <p className="creato-f-l mb-6">
                        When ordering or registering on our site, you may be asked to enter your: name, email address, mailing address, or phone number. You may, however, visit our site anonymously.
                    </p>
                    <p className="creato-f-r mb-4">
                        <strong className="creato-f-m">What Do We Use Your Information For?</strong>
                    </p>
                    <ul className="creato-f-l list-disc pl-5 mb-6">
                        <li>To personalize your experience and respond to your needs.</li>
                        <li>To improve our website based on user feedback.</li>
                        <li>To improve customer service and support.</li>
                        <li>To administer contests, promotions, and surveys.</li>
                        <li>To send periodic emails for updates and promotions.</li>
                    </ul>
                    <p className="creato-f-r mb-4">
                        <strong className="creato-f-m">How Do We Protect Your Information?</strong>
                    </p>
                    <p className="creato-f-l mb-6">
                        We implement a variety of security measures to maintain the safety of your personal information when you enter, submit, or access your personal information.
                    </p>
                    <p className="creato-f-r mb-4">
                        <strong className="creato-f-m">Do We Disclose Any Information to Outside Parties?</strong>
                    </p>
                    <p className="creato-f-l mb-6">
                        We do not sell, trade, or otherwise transfer your personally identifiable information to outside parties, except trusted third parties who assist us with our business operations and agree to keep this information confidential. Information may also be disclosed when required by law or to protect rights, property, or safety.
                    </p>
                    <p className="creato-f-r mb-4">
                        <strong className="creato-f-m">Online Privacy Protection</strong>
                    </p>
                    <p className="creato-f-l mb-6">
                        We value your privacy and do not distribute your personal information without your consent. Users may make changes to their information by contacting us at <a href="mailto:info@luxlifemiamirealestate.com" className="text-blue-500 underline">info@luxlifemiamirealestate.com</a>.
                    </p>
                    <p className="creato-f-r mb-4">
                        <strong className="creato-f-m">Online Privacy Policy Only</strong>
                    </p>
                    <p className="creato-f-l mb-6">
                        This policy applies only to information collected through our website and not offline.
                    </p>
                    <p className="creato-f-r mb-4">
                        <strong className="creato-f-m">Your Consent</strong>
                    </p>
                    <p className="creato-f-l mb-6">
                        By using our site, you consent to our privacy policy.
                    </p>
                    <p className="creato-f-r mb-4">
                        <strong className="creato-f-m">Changes to Our Privacy Policy</strong>
                    </p>
                    <p className="creato-f-l mb-6">
                        If we make changes to this policy, they will be posted on this page.
                    </p>
                    <p className="creato-f-r mb-4">
                        <strong className="creato-f-m">Contacting Us</strong>
                    </p>
                    <p className="creato-f-l">
                        If you have any questions regarding this privacy policy, you may contact us at:
                    </p>
                    <address className="creato-f-r not-italic mb-6">
                        Luxlife Miami Real Estate LLC<br />
                        Email: <a href="mailto:info@luxlifemiamirealestate.com" className="text-blue-500 underline">info@luxlifemiamirealestate.com</a>
                    </address>
                </div>
            </div>
            <FooterDirectory />

            <Footer />
        </>
    );
};

export default PrivacyPolicy;
