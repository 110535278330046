import React, { useState } from 'react';
import { InboxOutlined } from '@ant-design/icons';
import { message, Upload } from 'antd';
import uploadImg from '../../../assets/images/upload-img.png';
import Button from '../../buttons/Button';
import CryptoJS from 'crypto-js';

const { Dragger } = Upload;

const Uploader = ({ fileList, setFileList, uploader }) => {
    let getNewTokenn = localStorage.getItem("user_data");
    const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB
    const ALLOWED_FORMATS = ['image/jpeg', 'image/png', 'image/webp', 'image/heic'];
    const MAX_FILES = 10;

    const handleImageUpload = async ({ file, onSuccess, onError }) => {

        await uploader();

        const formData = new FormData();
        formData.append('file', file);
    
  
        
        const headers = {
            Authorization: `Bearer ${JSON.parse(getNewTokenn)?.token}`,
        };

        try {
            const response = await fetch('https://www.luxlifemiamirealestate.com/api/api/fileuploader', {
                method: 'POST',
                body: formData,
                headers
            });
    
            if (response.ok) {
                const responseData = await response.json();
                const uploadedFile = {
                    uid: file.uid,
                    name: file.name,
                    status: 'done',
                    url: 'https://www.luxlifemiamirealestate.com/api/storage/app/public/' + responseData.data,
                };
                
                setFileList((prev) => [...prev, uploadedFile]);
                onSuccess();
                message.success(`${file.name} uploaded successfully.`);
            } else {
                throw new Error('Upload failed');
            }
        } catch (error) {
            onError(error);
            message.error('File upload failed');
        }
    };

    const handleImageRemove = (file) => {
        setFileList((prev) => prev.filter((img) => img.uid !== file.uid));
    };

    const props = {
        name: 'file',
        multiple: true,
        fileList,
        customRequest: handleImageUpload,
        onRemove: handleImageRemove,
        beforeUpload(file) {
            if (file.size > MAX_FILE_SIZE) {
                message.error(`${file.name} exceeds the maximum size of 10MB.`);
                return false;
            }
            if (!ALLOWED_FORMATS.includes(file.type)) {
                message.error(`${file.name} has an unsupported file format.`);
                return false;
            }
            return true;
        },
        className: 'uploader-properties',
    };

    return (
        <Dragger {...props}>
            <div className="flex justify-center">
                <div className="w-[200px] lg:w-[10vw] hidden md:flex h-[200px] lg:h-[10vw] bg-theme_blue text-theme_yellow flex-col justify-center items-center rotate-[-10deg] border border-white rounded-lg">
                    <p className="text-[12px] lg:text-[0.7vw]">Drag files here to upload.</p>
                    <img src={uploadImg} alt="" className="object-cover max-w-[100px] lg:max-w-[5vw] mt-4" />
                </div>
                <div className="w-[200px] lg:w-[10vw] h-[200px] lg:h-[10vw] bg-theme_blue text-theme_yellow flex flex-col justify-center items-center md:ml-[-20px] border border-white rounded-lg">
                    <img src={uploadImg} alt="" className="object-cover max-w-[100px] lg:max-w-[5vw] mt-4" />
                </div>
            </div>
            <div className="p-4">
                <p className="ant-upload-text">or</p>
                <Button title="Select files" className="py-2 px-8 rounded-full mt-4" />
            </div>
        </Dragger>
    );
};

export default Uploader;
